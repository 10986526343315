<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CRow>
          <CCol sm="12">
            <CCard>
              <CCardHeader>
                <CCardTitle>Details of Defect #{{ defect.id }}</CCardTitle>
                <div class="card-header-actions"></div>
              </CCardHeader>

              <CCardBody>
                <Form :data="defect"></Form>
              </CCardBody>
            </CCard>
          </CCol>

          <CCol sm="12">
            <CButton
              variant="outline"
              type="submit"
              color="primary"
              @click="updateDefect"
            >
              <CIcon name="cil-check-circle" />
              Update
            </CButton>
            <CButton
              class="float-right"
              variant="outline"
              type="submit"
              color="danger"
              @click="deleteDefect"
            >
              <CIcon name="cil-trash" /> Delete
            </CButton>
          </CCol>
        </CRow>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import DefectAPI from "/app/src/api/defect.js";

import Form from "./Form";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "Detail",
  components: { Form },
  data: () => {
    return {
      defectAPI: new DefectAPI(),
      defect: {},
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "bold",
          "italic",
          "|",
          "link",
          "blockquote",
          "inserttable",
          "|",
          "bulletedlist",
          "numberedlist",
          "|",
          "undo",
          "redo",
        ],
      },
    };
  },
  created: function () {
    console.log(process.env.VUE_APP_STORAGE_URL);
    this.getDefect(this.$route.params.id);
  },
  methods: {
    getDefect: function (id) {
      this.$store.dispatch("loading");
      let self = this;
      self.defectAPI
        .detail(id)
        .then((defect) => {
          this.$store.dispatch("stopLoading");
          self.defect = defect;
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading");
          console.log(error);
          this.$alert.show({ type: "danger", message: error });
        });
    },
    validateSubmission: function () {
      if (this.defect.product_id < 1) return "Product is required";
      if (this.defect.part_id < 1) return "Part is required";
      return true;
    },
    updateDefect: function (id) {
      console.log(this.defect);

      let message = this.validateSubmission();
      if (message !== true) {
        this.$alert.show({ type: "danger", message: message });
        return;
      }

      this.$store.dispatch("loading");
      let self = this;
      let data = this.defect;
      this.defectAPI
        .update(data)
        .then((defect) => {
          self.$store.dispatch("stopLoading");
          self.$alert.show({
            type: "success",
            message: "Defect has been updated",
          });
          self.defect = defect;
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("stopLoading");
          this.$alert.show({ type: "danger", message: error });
        });
    },
    deleteDefect: function (id) {
      const params = {
        title: "Confirm",
        text: "Are you sure you want to delete?",
        onConfirm: () => {
          this.$store.dispatch("loading");
          this.defectAPI
            .delete(this.$route.params.id)
            .then((result) => {
              this.$store.dispatch("stopLoading");
              console.log(result);
              this.$router.push({ path: "/manager/defects" });
            })
            .catch((error) => {
              this.$store.dispatch("stopLoading");
              console.log(error);
              this.$alert.show({ type: "danger", message: error });
            });
        },
      };
      this.$confirm.show(params);
    },
  },
};
</script>

<style scoped></style>
